import React from 'react'
import classnames from 'classnames'
import liImg from './../../images/newdesign/linkedin.png'

const Footer = () => {
  return <div className={classnames('container')}>
    <footer className={classnames('d-flex', 'flex-wrap', 'justify-content-between', 'align-items-center', 'py-4', 'border-top')}>
      <div className={classnames('col-md-4', 'd-flex', 'align-items-center')}>
        <span className={classnames("mb-3", "mb-md-0", "text-muted")}>
          <small>© 2023 Probe sp. z o.o. NIP: 6772464487, KRS: 0000901130</small><br/>
          <small>ul. Morelowa 4C/19, 30-222 Krakow</small>
        </span>
      </div>
      <ul className={classnames("nav", "col-md-4", "justify-content-end", "list-unstyled", "d-flex")}>
        <li className="ms-3">
          <a className="text-muted" href="https://blog.getprobe.io/">
            <small>Probe Blog</small>
          </a>
        </li>
        <li className="ms-3">
          <a className="text-muted" href={`mailto:${process.env.GATSBY_SUPPORT_EMAIL}`}>
            <small>Contact Us</small>
          </a>
        </li>
        <li className="ms-3">
          <a className="text-muted" href="/privacy">
            <small>Privacy &amp; terms</small>
          </a>
        </li>
        <li className="ms-3">
          <a className="text-muted" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/getprobe">
            <img src={liImg} alt='LinedIn' />
          </a>
        </li>
      </ul>
    </footer>
  </div>
}

export default Footer

// extracted by mini-css-extract-plugin
export var customersLogosContent = "CustomersPromo-module--customersLogosContent--Jr9tT";
export var customersLogos = "CustomersPromo-module--customersLogos--iQbmv";
export var devskiller = "CustomersPromo-module--devskiller--fliP5";
export var autenti = "CustomersPromo-module--autenti--QkCKR";
export var authologic = "CustomersPromo-module--authologic--eqczR";
export var callpage = "CustomersPromo-module--callpage--Yrcri";
export var edrone = "CustomersPromo-module--edrone--F4i8J";
export var castle = "CustomersPromo-module--castle--NCLyw";
export var primetric = "CustomersPromo-module--primetric--W0DZh";
export var netpeak = "CustomersPromo-module--netpeak--miu0p";
export var rocketdata = "CustomersPromo-module--rocketdata--1Zzhq";
export var migam = "CustomersPromo-module--migam--Ub-SB";
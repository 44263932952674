// extracted by mini-css-extract-plugin
export var casestudy = "caseStudyLayout-module--casestudy--Ses-O";
export var title = "caseStudyLayout-module--title--NzssB";
export var navigation = "caseStudyLayout-module--navigation--OUSM9";
export var heroYellow = "caseStudyLayout-module--heroYellow--WRrMh";
export var heroBlack = "caseStudyLayout-module--heroBlack--Xcgt6";
export var heroBlue = "caseStudyLayout-module--heroBlue--Z81Un";
export var hero = "caseStudyLayout-module--hero--6QB1K";
export var heroContent = "caseStudyLayout-module--heroContent--3OJ7E";
export var content = "caseStudyLayout-module--content--dPx4G";
export var quoteText = "caseStudyLayout-module--quoteText--djE+f";
export var quoteSign = "caseStudyLayout-module--quoteSign--7QdXK";
export var star = "caseStudyLayout-module--star--WdkVL";
export var dots = "caseStudyLayout-module--dots--76YYe";
export var slider = "caseStudyLayout-module--slider--zx-KV";
export var arrow = "caseStudyLayout-module--arrow--dcDeA";
export var logo = "caseStudyLayout-module--logo--yCjHj";
export var valerie = "caseStudyLayout-module--valerie--+oS3S";
export var lukasz = "caseStudyLayout-module--lukasz--ZEoWP";
export var aleksander = "caseStudyLayout-module--aleksander--beokK";
export var jakub = "caseStudyLayout-module--jakub--GtkU1";
export var circleGradienBackground = "caseStudyLayout-module--circleGradienBackground--QidCj";
export var castleLogo = "caseStudyLayout-module--castleLogo--Osxum";
export var edroneLogo = "caseStudyLayout-module--edroneLogo--xm3er";
export var callpageLogo = "caseStudyLayout-module--callpageLogo--vF3-I";
export var devskillerLogo = "caseStudyLayout-module--devskillerLogo--FsBb5";
export var cta = "caseStudyLayout-module--cta--okuQE";
export var ctaFilled = "caseStudyLayout-module--ctaFilled--2oCg7";
export var barLink = "caseStudyLayout-module--barLink--zymtS";
export var avatar = "caseStudyLayout-module--avatar--D8nFn";
export var whiteText = "caseStudyLayout-module--whiteText--rFbZF";
export var fullBrightness = "caseStudyLayout-module--fullBrightness--XXloG";
export var otherCaseStudy = "caseStudyLayout-module--otherCaseStudy--5cEho";
import React from 'react'
import classnames from 'classnames'

import * as styles from './fullWidth.module.css'

const FullWidth = ({ backgroundColorClass, className, children }) => {
  return (
    <div className={classnames(backgroundColorClass, className)}>
      <div className={styles.container}>{children}</div>
    </div>
  )
}

export default FullWidth

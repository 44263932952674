import React from 'react'
import { NavBar } from '../casestudy/caseStudyLayout'
import Footer from './Footer'

import * as css from './layout.module.css'

const Layout = ({children}) => {
  return <div className={css.layout}>
    <NavBar />
    {children}
    <Footer />
  </div>
}

export default Layout

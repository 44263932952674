import React from 'react'
import classnames from 'classnames'

import logoImage from '../../images/black-logo.svg'

import * as css from './caseStudyLayout.module.css'

export const NavBar = () => {
  return (
    <div className={classnames(css.navigation, 'sticky-top')}>
      <div
        className={classnames('container', 'col-xxl-10', 'py-3', 'sticky-top')}
      >
        <nav
          className={classnames('navbar', 'navbar-expand-lg', 'navbar-light')}
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggler"
              aria-controls="navbarToggler"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand" href="/">
              <img
                src={logoImage}
                alt="logo"
                width="117"
                height="30"
                className="mb-1"
              />
            </a>
            <div
              className={classnames('collapse', 'navbar-collapse')}
              id="navbarToggler"
            >
              <ul className={classnames('navbar-nav', 'me-auto')}>
                <li className={classnames('nav-item', 'mb-0', 'px-3')}>
                  <a
                    className={classnames(css.barLink, 'nav-link', 'active')}
                    href={`${process.env.GATSBY_APP_URL}/case-studies/castle/`}
                  >
                    Case studies
                  </a>
                </li>
                <li className={classnames('nav-item', 'mb-0', 'px-3')}>
                  <a
                    className={classnames(css.barLink, 'nav-link', 'active')}
                    href={`${process.env.GATSBY_APP_URL}/saas-metrics/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    SaaS calculators
                  </a>
                </li>
                <li className={classnames('nav-item', 'mb-0', 'px-3')}>
                  <a
                    className={classnames(css.barLink, 'nav-link', 'active')}
                    href="https://eepurl.com/gTAek1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Subscribe to newsletter
                  </a>
                </li>
              </ul>
              <a
                className={classnames('btn', css.cta)}
                href="https://calendly.com/alex-getprobe-io/30min"
                target="_blank"
                rel="noreferrer"
              >
                Schedule demo
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export const QuoteWithPhoto = ({ text, customerPhoto }) => {
  return (
    <div className={classnames('d-flex', 'py-5', 'flex-column')}>
      <div
        className={classnames(
          css.quoteText,
          'me-auto',
          'display-6',
          'text-center'
        )}
      >
        {text}
      </div>
      {customerPhoto}
    </div>
  )
}

export const Quote = ({ text }) => {
  return (
    <div className={classnames('d-flex', 'flex-row', 'py-5')}>
      <div className={classnames(css.quoteSign)}></div>
      <div className={classnames(css.quoteText, 'me-auto', 'display-6')}>
        {text}
      </div>
    </div>
  )
}

export const StarDivider = () => (
  <div className={classnames(css.star, 'my-4', 'mx-auto')}></div>
)

export const DotsDivider = () => (
  <div className={classnames(css.dots, 'my-4', 'mx-auto')}></div>
)

export const SliderDivider = () => (
  <div className={classnames(css.slider, 'my-4', 'mx-auto')}></div>
)

export const CustomerPhoto = ({ name, title, company, webPage }) => {
  const photoClass = () => {
    if (name === 'Valerie Kirk') {
      return css.valerie
    }

    if (name === 'Lukasz Chwistek') {
      return css.lukasz
    }

    if (name === 'Aleksander Gorczyca') {
      return css.aleksander
    }

    if (name === 'Jakub Kubryński') {
      return css.jakub
    }

    return ''
  }

  return (
    <div
      className={classnames(
        css.avatar,
        'd-flex',
        'flex-column',
        'py-5',
        'align-items-center'
      )}
    >
      <div className={css.arrow}></div>
      <div className={classnames(css.circleGradienBackground, 'mb-2')}>
        <div className={photoClass()}></div>
      </div>
      <h5>{name}</h5>
      <p>
        {title} at{' '}
        <a href={webPage} target="_blank" rel="noreferrer">
          {company}
        </a>
      </p>
    </div>
  )
}

export const CastleHero = () => (
  <div className={css.heroContent}>
    <div
      className={classnames(
        css.hero,
        css.heroYellow,
        'my-4',
        'd-flex',
        'flex-column',
        'mx-auto',
        'p-2',
        'p-sm-5'
      )}
    >
      <div className={classnames(css.castleLogo, 'align-self-start')}></div>
      <h1
        className={classnames(
          css.title,
          'display-5',
          'mt-auto',
          'align-self-end'
        )}
      >
        Probe case-study Q&A
      </h1>
    </div>
  </div>
)

export const EdroneHero = () => (
  <div className={classnames(css.heroContent)}>
    <div
      className={classnames(
        css.hero,
        css.heroBlack,
        'my-4',
        'd-flex',
        'flex-column',
        'mx-auto',
        'p-2',
        'p-sm-5'
      )}
    >
      <div className={classnames(css.edroneLogo)}></div>
      <h1
        className={classnames(css.title, css.whiteText, 'display-5', 'mt-auto')}
      >
        Probe
        <br />
        case-study Q&A
      </h1>
    </div>
  </div>
)

export const CallpageHero = () => (
  <div className={classnames(css.heroContent)}>
    <div
      className={classnames(
        css.hero,
        css.heroBlue,
        'my-4',
        'd-flex',
        'flex-column',
        'align-items-end',
        'mx-auto',
        'p-2',
        'p-sm-5'
      )}
    >
      <div className={classnames(css.callpageLogo)}></div>
      <h1
        className={classnames(
          css.title,
          css.whiteText,
          'text-end',
          'display-5',
          'mt-auto'
        )}
      >
        Probe
        <br />
        case-study Q&A
      </h1>
    </div>
  </div>
)

export const DevskillerHero = () => (
  <div className={classnames(css.heroContent)}>
    <div
      className={classnames(
        css.hero,
        css.heroBlack,
        'my-4',
        'd-flex',
        'flex-column',
        'mx-auto',
        'p-2',
        'p-sm-5'
      )}
    >
      <div className={classnames(css.devskillerLogo)}></div>
      <h1
        className={classnames(css.title, css.whiteText, 'display-5', 'mt-auto')}
      >
        Probe
        <br />
        case-study Q&A
      </h1>
    </div>
  </div>
)

export const CaseStudyCards = ({names}) => {
  const studies = {
    callpage: {
      logo: css.callpageLogo,
      title: 'Callpage reduced time for getting KPIs from 15 days to an hour',
      link: '/case-studies/callpage-cfo',
    },
    edrone: {
      logo: css.edroneLogo,
      title: 'How Edrone uses Probe to replace its in-house BI',
      link: '/case-studies/edrone-ops',
    },
    castle: {
      logo: css.castleLogo,
      title: 'How Castle uses Probe to automate analytics',
      link: '/case-studies/castle',
    },
    devskiller: {
      logo: css.devskillerLogo,
      title: 'DevSkiller: “With Probe we finally get metrics we can trust”',
      link: '/case-studies/devskiller',
    },
  }

  return <div className={classnames('d-flex', 'flex-row', 'flex-wrap')}>
    {names.map((name) => (
      <div
        className={classnames(
          css.otherCaseStudy,
          'd-flex',
          'flex-column',
          'justify-content-between',
          'me-4',
          'mb-5',
          'col'
        )}
      >
        <a href={studies[name].link}>
          <div
            className={classnames(
              studies[name].logo,
              css.fullBrightness,
              'mb-3'
            )}
          ></div>
          {studies[name].title}
        </a>
      </div>
    ))}
  </div>
}

export const OtherCaseStudies = ({ names }) => {
  return (
    <div className="py-4">
      <h1 className={classnames('display-5', 'mb-5')}>
        Other
        <br />
        case-studies
      </h1>
      <CaseStudyCards names={names} />
      <div className="pb-5"></div>
    </div>
  )
}

const CaseStudyLayout = ({ children, hero, title }) => {
  return (
    <div className={css.casestudy}>
      <NavBar />
      {hero}
      <h1
        className={classnames(
          css.heroContent,
          'display-4',
          'text-center',
          'py-4'
        )}
      >
        {title}
      </h1>
      <div className={css.content}>{children}</div>
    </div>
  )
}

export default CaseStudyLayout

import React from 'react'
import FullWidth from './fullWidth'

import * as styles from './CustomersPromo.module.css'

const CustomersPromo = () => {
  return (
    <FullWidth>
      <div className={styles.customersLogosContent}>
        <div className={styles.customersLogos}>
          <div className={styles.devskiller}></div>
          <div className={styles.autenti}></div>
          <div className={styles.authologic}></div>
          <div className={styles.edrone}></div>
          <div className={styles.callpage}></div>
          <div className={styles.castle}></div>
          <div className={styles.primetric}></div>
          <div className={styles.netpeak}></div>
          <div className={styles.rocketdata}></div>
          <div className={styles.migam}></div>
        </div>
      </div>
    </FullWidth>
  )
}

export default CustomersPromo
